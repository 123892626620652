<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-img
          v-bind="attrs"
          v-on="on"
          contain
          height="32"
          width="32"
          :class="{ hover: attrs['aria-expanded'] == 'true' }"
          class="btn-img ml-2 mr-3 rounded-circle"
          :src="require(`@/assets/flags/${$i18n.locale}.svg`)"
        />
      </template>
      <v-list class="drop-down-menu">
        <v-list-item-group @change="onSelectLanguage" color="primary">
          <v-list-item
            v-for="(locale, index) in getAvailableLanguages"
            :key="index"
          >
            <v-img
              contain
              height="32"
              max-width="32"
              width="32"
              class="mr-3 rounded-circle"
              :src="require(`@/assets/flags/${locale.code}.svg`)"
            />
            <v-list-item-content>
              <v-list-item-title class="d-flex">
                <span class="d-block mr-6">{{ locale.name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { availableLanguages, loadLanguageAsync } from "@/plugins/i18n";

export default {
  data: () => ({}),
  computed: {
    getAvailableLanguages() {
      return availableLanguages;
    },
  },

  methods: {
    async onSelectLanguage(index) {
      const localesArray = Object.keys(availableLanguages);
      await loadLanguageAsync(localesArray[index]);
    },
  },
};
</script>

<style type="text/css">
.btn-img {
  cursor: pointer;
  opacity: 0.5;
}

.btn-img:hover,
.btn-img.hover {
  opacity: 1;
}

.drop-down-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
