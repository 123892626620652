module.exports = {
  contract: process.env.VUE_APP_CONTRACT
    ? process.env.VUE_APP_CONTRACT
    : "0x4a59b602BA11D07b8D17e8980c4ff702A307BAE5", // ETH VOTE
  contractPolygon: process.env.VUE_APP_CONTRACT_POLYGON
    ? process.env.VUE_APP_CONTRACT_POLYGON
    : "0x31358D2a291798a9143764d4b1Df0064f397D524", // Polygon VOTE
  erc223Polygon: "0xC3F6434df5Ef44798610c921942E49822c6f5108",
  erc223Ethereum: "0x91da1145876a25ffAd47f34DA3559a18Caae811d",
  wssUrl:
    process.env.VUE_APP_NODE_ENV === "production"
      ? "wss://wss.decentral-art.com/"
      : "wss://wss_test.decentral-art.com/",
  apiUrl:
    process.env.VUE_APP_NODE_ENV === "production"
      ? "https://api.decentral-art.com" // change to https://api.decentral-art.com before production
      : "https://api-test.decentral-art.com",
};
