<template>
  <div class="wrap d-flex flex-wrap pt-10">
    <div>
      <div class="top-1">{{ $t("components.company") }}</div>
      <div class="top-2">
        {{ formatNumber(web3.company) }} {{ $t("global.art") }}
      </div>
      <div class="top-2">{{ formatNumber(web3.eth) }} MATIC</div>
    </div>
    <div>
      <div class="top-1">{{ $t("components.totalInContractWithRewards") }}</div>
      <div class="top-2">
        {{ formatNumber(web3.totalArt) }} {{ $t("global.art") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},

  data() {
    return {};
  },

  computed: {
    web3() {
      return this.$store.state.web3;
    },
  },

  methods: {
    formatNumber(number) {
      return (number / 10 ** 18).toFixed(4);
    },
  },
};
</script>

<style lang="scss">
.wrap {
  > div {
    flex: 1;
    min-width: 200px;
    @media (max-width: 600px) {
      flex: initial;
      margin-bottom: 1em;
    }
  }

  .top-1 {
    font-size: 14px;
    font-weight: bold;
    color: #58667e;
    text-transform: uppercase;
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }

  .top-2 {
    font-size: 40px;
    font-weight: bold;
    color: #d4af37;
    @media (max-width: 992px) {
      font-size: 1rem;
    }
  }
}
</style>
