<template>
  <v-app>
    <v-main>
      <v-container>
        <layout v-if="$route.meta.layout === 'default'">
          <router-view :key="$route.fullPath" />
        </layout>
        <auth-layout v-if="$route.meta.layout === 'auth'">
          <router-view :key="$route.fullPath" />
        </auth-layout>
        <error-layout v-if="$route.meta.layout === 'error'">
          <router-view :key="$route.fullPath" />
        </error-layout>

        <web3-modal-vue
          ref="web3modal"
          :theme="theme"
          :provider-options="$web3ProviderOptions"
          cache-provider
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Layout from "./layouts/default";
import AuthLayout from "./layouts/auth";
import ErrorLayout from "./layouts/error";

import Web3ModalVue from "web3modal-vue";
import { web3Modal } from "./config/mixins";
import { loadLanguageAsync } from "@/plugins/i18n";
import { getCookie } from "@/helpers/cookies";

export default {
  name: "App",
  mixins: [web3Modal],
  components: { Web3ModalVue, Layout, AuthLayout, ErrorLayout },
  data() {
    return {
      modal: null,
      theme: "dark",
      number: 0,
      balance: 0,
      logged: false,
    };
  },
  created() {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.theme = "dark";
    }
  },
  mounted() {
    this.$nextTick(async () => {
      const i18nCookie = getCookie("i18n");
      if (!i18nCookie) {
        await loadLanguageAsync(window.navigator.language);
      } else {
        await loadLanguageAsync(i18nCookie);
      }

      this.$store.commit("setWeb3Modal", this.$refs.web3modal);
    });
  },
};
</script>
