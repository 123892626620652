import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  base: "/",
  mode: "history",
  linkActiveClass: "active",
});

/*router.beforeEach((routeTo, routeFrom, next) => {
  const auth = routeTo.matched.some((route) => route.meta.auth);

  if (routeTo.name === "login" && !store.getters["loggedIn"]) {
    return next();
  }

  if (auth && !store.getters["loggedIn"]) {
    return next("/login");
  }

  if (auth && store.getters["loggedIn"]) {
    return next();
  }

  if (routeTo.name === "login" && store.getters["loggedIn"]) {
    return next("/");
  }

  if (!auth) {
    return next();
  }
});*/

export default router;
