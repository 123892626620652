<template slot="md-tab">
  <div class="body-wrap content">
    <div>
      <div class="header d-flex justify-space-between align-center mt-2 mb-10">
        <router-link class="mr-auto" to="/">
          <img src="../assets/logo.svg?data" id="logo" />
          <img src="../assets/mobile-logo.svg?data" id="mobile-logo" />
        </router-link>
        <Header />
        <HeaderMobile />
      </div>

      <v-container>
        <wallet-amount />
        <slot v-if="web3.account" />
        <div v-else class="d-flex justify-center align-center btn-wrapper">
          <div class="d-flex flex-column align-center">
            <v-btn
              @click="web3.web3Modal.toggleModal()"
              class="create-btn"
              outlined
              text
              >{{ $t("buttons.connectToWallet") }}</v-btn
            >
            <div class="py-5">
              {{ $t("global.pleaseConnectToYourWalletToContinue") }}
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/header";
import HeaderMobile from "@/components/header/headerMobile";
import WalletAmount from "@/components/walletAmount";

export default {
  components: {
    Header,
    HeaderMobile,
    WalletAmount,
  },

  data() {
    return {};
  },

  computed: {
    web3() {
      return this.$store.state.web3;
    },
  },
};
</script>

<style lang="scss">
.kPFXFE.web3modal-modal-card {
  max-width: 600px;
}
.create-btn {
  height: 56px !important;
  background: rgba(212, 175, 55, 0.87);
  color: white !important;
  border: transparent;
}

.header {
  max-height: 40px;
}

#logo {
  @media (max-width: 600px) {
    display: none;
  }
  max-width: 200px;
}

#mobile-logo {
  @media (min-width: 601px) {
    display: none;
  }
  display: block;
  height: 50px;
}

.wrap {
  width: 100%;
  margin: 0px auto;
  max-width: 1050px;
}

.content {
  min-height: 98vh;
}

.active-link {
  background: #edf0f3;
}

.body-wrap {
  .container {
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;

    font-family: inherit;
    color: inherit;
    text-align: inherit;
    box-sizing: inherit;
    display: inherit;
    flex-direction: inherit;
    flex-grow: inherit;
    flex-shrink: inherit;
    justify-content: inherit;
    max-height: inherit;
    top: inherit;
    position: inherit;
    max-width: inherit;

    .btn-wrapper {
      height: calc(100vh - 550px);
    }
  }
  p {
    margin: 0;
    padding-bottom: inherit;
  }
}
</style>
