import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../assets/locales/en.json";
import { getAvailableLanguages } from "@/assets/locales";
import { setCookie } from "@/helpers/cookies";

Vue.use(VueI18n);

const availableLanguages = getAvailableLanguages();
const defaultCode = "en";
const loadedLanguages = [defaultCode];
const messages = { [defaultCode]: en };
export const i18n = new VueI18n({
  locale: defaultCode,
  fallbackLocale: defaultCode,
  messages,
});

const setI18nLanguage = (code) => {
  i18n.locale = code;
  document.querySelector("html").setAttribute("lang", code);

  return code;
};

export function loadLanguageAsync(iso) {
  let code = iso;
  const trimmedCode = iso.split("-")[0];

  if (availableLanguages[trimmedCode]) {
    code = trimmedCode;
  }

  if (!availableLanguages[trimmedCode] && !availableLanguages[iso]) {
    code = defaultCode;
  }

  setCookie("i18n", code);

  // If the same language
  if (i18n.locale === code) {
    return Promise.resolve(setI18nLanguage(code));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(code)) {
    return Promise.resolve(setI18nLanguage(code));
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */ `../assets/locales/${code}.json`
  ).then((messages) => {
    i18n.setLocaleMessage(code, messages);
    loadedLanguages.push(code);
    return setI18nLanguage(code);
  });
}

export { availableLanguages };
