//import web3 from "web3";
import Web3ModalVue from "web3modal-vue";
import config from "./../../config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import MewConnect from "@myetherwallet/mewconnect-web-client";

let vueInstance = null;
const ABI = require("./../assets/DecentralArt.json");
const ABI_ERC223 = require("./../assets/FxERC223ART.json");
const ABI_ERC223Root = require("./../assets/FxERC223Root.json");
const ABI_Polygon = require("./../assets/VotePolygon.json");
const infuraId = "5e14bfd880a54d1a9e954473fa748de2";
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    /*options: {
      infuraId: infuraId,
      apiKey: "dcc170d7f2db486ab3f09a98311e66c5",
      rpc: {
        1337: "https://decentral-art.com/wss",
      },
    },*/
    options: {
      infuraId: infuraId,
      apiKey: "dcc170d7f2db486ab3f09a98311e66c5",
      rpc: {
        1: "https://mainnet.infura.io/v3/5e14bfd880a54d1a9e954473fa748de2",
        137: "https://polygon-mainnet.infura.io/v3/5e14bfd880a54d1a9e954473fa748de2",
        5: "https://goerli.infura.io/v3/5e14bfd880a54d1a9e954473fa748de2",
        80001: "https://polygon-mumbai.infura.io/v3/5e14bfd880a54d1a9e954473fa748de2"
      }, // https://rpc.sepolia.org/
    },
  },
  mewconnect: {
    package: MewConnect, // required
    options: {
      //infuraId: infuraId, // required
      //network: "sepolia",
      //rpc: "wss://sepolia.infura.io/ws/v3/90952d0af11c4bccbcee97c79100061d",
      rpc: "https://mainnet.infura.io/v3/dcc170d7f2db486ab3f09a98311e66c5",
      network: 1,
    },
  },
};

const Contract = require("web3-eth-contract");
let myContract = null;
let erc223Contract = null;

const parseEvent = function (error, event) {};
//const networks = [5, 80001];
const networks = [1, 137];
const registerContract = (library) => {
  //console.log("registerContract", library.provider.chainId, networks);
  if (!myContract) {
  Contract.setProvider(library.provider);
    if (library.provider.chainId == networks[1]) {
      myContract = new Contract(ABI_Polygon, config.contractPolygon);
      //console.log("polygon", myContract);
      erc223Contract = new Contract(ABI_ERC223, config.erc223Polygon);
    }
    if (library.provider.chainId == networks[0]) {
      myContract = new Contract(ABI, config.contract);
      //console.log("sepolia", myContract);
      erc223Contract = new Contract(ABI_ERC223Root, config.erc223Ethereum);
      //console.log(erc223Contract);
    }
    //console.log(parseInt(library.provider.chainId));

    /*myContract.events.allEvents({}, parseEvent);
    myContract.getPastEvents('allEvents', {
      fromBlock: 0,
      topics: [
        "0x534f1d8d2c3b462b484917bfaed9564a69bfc7f31710cd9f5071d8881d5c78d0", // ProposalCreated
      ]
    }, (error, events) => console.log(error,events));*/
  }
};

const Call = function (method, ...params) {
  //console.log(method, params, erc223Contract);
  //registerContract(vueInstance.$store.state.web3.library);
  let callParams = {},
    account = vueInstance.$store.state.web3.account;
  if (account != "") {
    callParams = { from: account };
  }
  // console.log(method, ...params);
  if (["balanceOf"].indexOf(method) != -1) {
    //console.log(erc223Contract);
    return erc223Contract.methods[method](...params).call(callParams);
  } else {
    return myContract.methods[method](...params).call(callParams);
  }
};

const Send = function (method, sendParams, ...params) {
  //registerContract(vueInstance.$store.state.web3.library);
  let callParams = {},
    account = vueInstance.$store.state.web3.account;
  if (account != "") {
    sendParams = Object.assign(sendParams, { from: account });
  }

  if (["saveBatchTransferToMultipleWallets"].indexOf(method) != -1) {
    return erc223Contract.methods[method](...params).send(sendParams);
  } else {
    return myContract.methods[method](...params).send(sendParams);
  }
};

const EtherscanUrl = (hash, type = "tx", network = null) => {
  let url = vueInstance.$root.$store.state.web3.network == 0 ? "https://etherscan.io/" : "https://polygonscan.com/";
  if(network != null){
    url = network == 0 ? "https://etherscan.io/" : "https://polygonscan.com/";
  }
  //let url = "https://polygonscan.com/";
  //let url = "https://mumbai.polygonscan.com/";
  //let url = "https://etherscan.io/";
  switch(type){
    case "address": return url+"address/" + hash;
    case "address20": return url+"address/" + hash + "#tokentxns";
    case "721": return url+"address/" + hash + "#tokentxnsErc721";
    case "token": return url+"token/" + hash;
    case "internal": return url+"tx/" + hash + "#internal";
    default: return url+"tx/" + hash;
  }
} 

const Blockchain = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$web3Call = Call;
    Vue.prototype.$web3Send = Send;
    Vue.prototype.$web3ProviderOptions = providerOptions;
    Vue.prototype.$registerContract = registerContract;
    Vue.prototype.$etherscanUrl = EtherscanUrl;
    Vue.mixin({
      beforeCreate() {
        vueInstance = this;
      },
    });
  },
  call: Call,
  send: Send,
};

export default Blockchain;
