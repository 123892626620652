export default {
  data() {
    return {
      windowWidth: innerWidth,
    };
  },

  created() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
