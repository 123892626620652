const priceFormatter = (amount, fiat = false, locale = "pl") => {
  if (fiat) {
    return Number(amount).toLocaleString(locale, {
      style: "currency",
      currency: "USD",
    });
  }

  if (typeof amount === "string") {
    return amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  if (typeof amount === "number") {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
};

export { priceFormatter };
