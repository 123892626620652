<template slot="md-tab">
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.md-toolbar-section-end {
  justify-content: center;
}
</style>
