<template>
  <div class="main-menu px-1 d-flex">
    <LanguageSwitcher />
    <NetworkSwitcher />
    <router-link
      v-if="windowWidth > 768"
      :class="{ 'active-link': $route.path === '/wallets' }"
      to="/wallets"
      >{{ $t("global.wallets") }}</router-link
    >
    <div v-if="web3.active" class="wallet-amount d-flex align-center mx-2">
      {{ web3.balance }} ETH
    </div>
  </div>
</template>
<script>
import LanguageSwitcher from "@/components/language-switcher";
import NetworkSwitcher from "@/components/network-switcher";
import windowWidth from "@/mixins/windowWidth";
export default {
  mixins: [windowWidth],
  components: {
    LanguageSwitcher,
    NetworkSwitcher,
  },

  data() {
    return {};
  },

  computed: {
    web3() {
      return this.$store.state.web3;
    },
  },
};
</script>

<style lang="scss">
.main-menu {
  @media (max-width: 780px) {
    display: none;
  }
  a {
    text-decoration: none;
    display: block;
    position: relative;
    font-size: 17px;
    font-weight: 500;
    color: #222531 !important;
    overflow: hidden;
    border-radius: 8px;
    padding: 3px 8px 3px 8px;
    margin: 3px 3px;
  }

  a:hover {
    background: #edf0f3;
  }

  .wallet-amount {
    @media (max-width: 425px) {
      display: none !important;
    }
    font-size: 17px;
    font-weight: 500;
  }
}
</style>
