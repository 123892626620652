export const shortAddress = (address, windowWidth) => {
  if (windowWidth > 960) {
    return address;
  } else if (windowWidth > 580) {
    return (
      address.substr(0, 6) + " … " + address.substr(address.length - 10, 10)
    );
  } else {
    return address.substr(0, 8) + " … " + address.substr(address.length - 8, 8);
  }
};
