const locales = [
  { code: "pl", iso: "pl", name: "Polish" },
  { code: "es", iso: "es", name: "Spanish" },
  { code: "ko", iso: "ko", name: "Korean" },
  { code: "tr", iso: "tr", name: "Turkish" },
  { code: "fr", iso: "fr", name: "French" },
  { code: "de", iso: "de", name: "German" },
  { code: "ar", iso: "ar-sa", name: "Arabic" },
  { code: "id", iso: "id", name: "Indonesian" },
  { code: "zh", iso: "zh-cn", name: "Chinese" },
  { code: "pa", iso: "pa-pk", name: "Urdu" },
  { code: "zh-hk", iso: "zh-Hans-HK", name: "Chinese" },
  { code: "en", iso: "en-US", name: "English" },
];

const getAvailableLanguages = () => {
  const availableLanguages = {};
  const requireModule = require.context(
    // Search for files in the current directory.
    ".",
    // Search for files in subdirectories.
    true,
    // Include any .js files that are not this file or a unit test.
    /^((?!index|\.unit\.).)*\.json$/
  );
  requireModule.keys().forEach((fileName) => {
    const modulePath = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");

    availableLanguages[modulePath] = locales.find(
      (item) => item.code === modulePath
    );
  });

  return availableLanguages;
};

export { getAvailableLanguages };
