<template>
  <div class="">
    <div class="header d-flex justify-center mt-2 mb-10">
      <a to="/">
        <img src="../assets/logo.svg?data" id="logo" />
        <img src="../assets/mobile-logo.svg?data" id="mobile-logo" />
      </a>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#logo {
  max-width: 200px;
}

@media (min-width: 601px) {
  #mobile-logo {
    display: none;
  }
}
@media (max-width: 600px) {
  #logo {
    display: none;
  }

  #mobile-logo {
    display: block;
    height: 50px;
  }
}
</style>
