<template>
  <div class="menu d-flex">
    <div
      v-if="windowWidth > 768"
      @click="verify"
      class="metamask"
      :class="{ notLogged: !web3.verify && web3.active }"
    >
      <div class="d-flex flex-row">
        <span v-if="web3.active">{{ shortAddress(web3.account) }}</span>
        <span v-else @click="openModal">{{ $t("buttons.connect") }}</span>
        <Avatar
          v-if="web3.active"
          class="pl-2"
          :size="25"
          :name="web3.account"
        />
      </div>
    </div>

    <v-menu content-class="mini-menu" bottom open-on-hover offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-2" v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <div class="d-flex flex-column">
        <div
          v-if="windowWidth <= 768 && web3.active"
          @click="verify"
          class="metamask"
          :class="{ notLogged: !web3.verify && web3.active }"
        >
          <div class="d-flex flex-row">
            <span v-if="web3.active">{{ shortAddress(web3.account) }}</span>
            <Avatar
              v-if="web3.active"
              class="pl-2"
              :size="25"
              :name="web3.account"
            />
          </div>
        </div>
        <a href="https://uni.decentral-art.com/">{{ $t("global.pageName") }}</a>
        <v-divider class="my-1"></v-divider>
        <router-link to="/">{{ $t("buttons.votes") }}</router-link>
        <router-link to="/wallets">{{ $t("buttons.wallets") }}</router-link>
        <router-link to="/transfer">{{ $t("buttons.transfer") }}</router-link>
        <a v-if="web3.active" @click="$store.dispatch('resetApp')">{{
          $t("buttons.disconnect")
        }}</a>
        <a v-else @click="openModal">{{ $t("buttons.connect") }}</a>
      </div>
    </v-menu>
  </div>
</template>

<script>
import Avatar from "../avatar";
import config from "/config.js";
import windowWidth from "@/mixins/windowWidth";
export default {
  mixins: [windowWidth],
  components: {
    Avatar,
  },

  data() {
    return {};
  },

  computed: {
    web3() {
      return this.$store.state.web3;
    },
  },
  watch: {
    "web3.active": function (val) {
      let user = window.$cookies.get("user");
      if (val) {
        if (typeof user != "undefined" && user != null) {
          this.$store.commit("setVerify", user);
          this.checkTokenExpire(user.tokenExpire);
        } else {
          this.verify();
        }
      }
    },
  },

  methods: {
    checkTokenExpire(expire) {
      let now = new Date().valueOf();
      if (now > expire) {
        if ((now - expire) / 1000 > 0) {
          var data = new FormData();
          data.append("jwt", this.web3.jwToken);
          data.append("wallet", this.web3.account);
          this.$request("POST", config.apiUrl + "/renew", data).then(
            (response) => {
              if (typeof response.jwt != "undefined") {
                this.$store.commit("set", {
                  verify: true,
                  active: true,
                  jwToken: response.jwt,
                  tokenExpire: new Date().valueOf() + 1000 * 60 * 180,
                });
              } else {
                this.$store.commit("set", { verify: false, jwToken: "" });
                this.verify();
              }
            }
          );
        } else {
          this.$store.commit("set", { verify: false, jwToken: "" });
          this.verify();
        }
        return true;
      } else {
        return false;
      }
    },
    async verify() {
      try {
        if (this.web3.verify) {
          return;
        }
        let ethereum = window.ethereum,
          resp = await this.$request(
            "GET",
            config.apiUrl + "/get-nonce?address=" + this.web3.account
          );
        let message = "Confirm message to login. \nNonce: " + resp.data;
        ethereum
          .request({
            method: "personal_sign",
            params: [message, this.web3.account],
          })
          .then((sign) => {
            var data = new FormData();
            data.append("wallet", this.web3.account);
            data.append("sign", sign);

            this.$request("POST", config.apiUrl + "/login", data)
              .then((response) => {
                response.data["setCookies"] = true;
                if (response.success) {
                  this.$store.commit("setVerify", response.data);
                  //this.$store.commit('uni/setNetwork', { network: response.data.network });
                } else {
                  console.log("error", response.errno);
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          })
          .catch((err) => {
            console.log("error", err);
            this.signWarn = true;
          });
      } catch (err) {
        console.log(err);
      }
    },
    shortAddress(address) {
      if (this.windowWidth > 764) {
        return (
          address.substr(0, 6) + " … " + address.substr(address.length - 4, 4)
        );
      } else {
        return (
          address.substr(0, 3) + " … " + address.substr(address.length - 2, 2)
        );
      }
    },

    openModal() {
      this.web3.web3Modal.toggleModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.metamask {
  border-radius: 4px;
  box-shadow: 0.5px 0.9px 5px 0 rgba(0, 0, 0, 0.1);
  background: #edf0f3;
  border: solid 1px #ebebeb;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  cursor: pointer;
  padding: 5px 10px 5px 10px;

  div {
    align-items: center;
    font-weight: 500;
  }
}

.metamask.notLogged {
  box-shadow: 0.5px 0.9px 5px 0 #ff5252;
}

.mini-menu {
  padding: 5px 5px;
  background: white;

  a {
    text-decoration: none;
    display: block;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #222531;
    padding: 6px 12px 6px 6px;
    margin: 3px 3px;
    border-radius: 6px;
    &:hover {
      background: #edf0f3;
    }
    span {
      width: 20px;
      display: inline-block;
      text-align: center;
      margin-right: 4px;
      vertical-align: sub;
    }
  }
}
</style>
