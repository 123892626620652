//import {getLibrary} from "@/utils/web3";
import { ethers } from "ethers";
import blockchain from "./../plugins/blockchain.js";
var waitingForClear = false;
var waitingForConnect = false;
var library;
//const networks = [5, 80001];
const networks = [1, 137];
const web3ModalStore = {
  state: {
    web3Modal: null,
    library: null, // getLibrary(),
    active: false,
    verify: false,
    jwToken: "",
    tokenExpire: 0,
    account: null,
    balance: "",
    chainId: 0,
    proprosals: [],
    eth: 0,
    totalArt: 0,
    company: 0,
    network: 0,
    myART: 0,
  },
  mutations: {
    set(state, newState) {
      state = Object.assign(state, newState);
    },
    setWeb3Modal(state, web3Modal) {
      state.web3Modal = web3Modal;
      if (web3Modal.cachedProvider) {
        this.dispatch("connect");
      }
      web3Modal.providerController.on("connect", (provider) => {
        if (!state.active) {
          this.dispatch("connect");
        }
      });
    },
    setLibrary(state, library) {
      state.library = library;
    },
    setActive(state, active) {
      state.active = active;
    },
    setVerify(state, data) {
      if (Object.keys(data).length < 3) return;
      state.verify = true;
      state.jwToken = data.jwt;
      state.tokenExpire = new Date().valueOf() + 1000 * 60 * 180;
      if (typeof data.setCookies != "undefined") {
        delete data.setCookies;
        data.tokenExpire = state.tokenExpire;
        //data.network = this.state.uni.network;
        window.$cookies.set("user", data, { path: "/" });
      }
    },
    setAccount(state, account) {
      state.account = account == null ? null : account.toLowerCase();
      if (account == null) {
        state.balance = "";
        state.active = false;
        state.verify = false;
        state.jwToken = "";
        window.$cookies.remove("user");
      }
    },
    setBalance(state, balance) {
      state.balance = balance;
    },
    setChainId(state, chainId) {
      state.chainId = chainId;
    },
    setContractInfo(state, data) {
      state.eth = parseFloat(data.eth);
      state.company = parseFloat(data.company);
      state.totalArt = parseFloat(data.totalArt);
    },
    getProprosals(state, count) {
      this.dispatch("getProprosals", count).then((proprosals) => {
        state.proprosals = proprosals;
      });
    },
  },

  getters: {
    companyBalance(state) {
      return state.company;
    },
  },

  actions: {
    changeNetwork({ state, commit, dispatch }, site) {
      //console.log("dd", site);
      if (typeof site == "undefined") return;
      //console.log(networks[site] == library.provider.chainId);
      let network = site;
      //waitingForConnect = false;
      library.provider
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + networks[network].toString("16") }],
        })
        .then(() => {
          location.reload();
          commit("setActive", false);
          setTimeout(() => {
            commit("setActive", true);
            this._vm.$registerContract(library);
          }, 200);
        })
        .catch(() => {
          /*let reqData = {
        method: 'wallet_addEthereumChain',
        params: [{ 
          "chainId": "0x" + networks[network].toString("16"),
          "chainName": network == 0 ? "Goerli test network" : "Mumbai Testnet",
          "rpcUrls": [network == 0 ? "https://rpc.goerli.org" : "https://rpc-mumbai.maticvigil.com"],
          "nativeCurrency": {
            "name": network == 0 ? "Goerli ETH" : "MATIC",
            "symbol": network == 0 ? "ETH" : "MATIC",
            "decimals": 18
          },
          "blockExplorerUrls": [network == 0 ? "https://goerli.etherscan.io" : "https://mumbai.polygonscan.com/"]
        }],
      };*/

          let reqData = {
            method: "wallet_addEthereumChain",
            /*params: [
              {
                chainId: "0x" + networks[network].toString("16"),
                chainName: network == 0 ? "Goerli test network" : "Mumbai Testnet",
                rpcUrls: [
                  network == 0
                    ? "https://rpc.goerli.org" : "https://rpc-mumbai.maticvigil.com"
                ],
                nativeCurrency: {
                  name: network == 0 ? "Goerli ETH" : "MATIC",
                  symbol: network == 0 ? "ETH" : "MATIC",
                  decimals: 18,
                },
                blockExplorerUrls: [
                  network == 0
                    ? "https://goerli.etherscan.io" : "https://mumbai.polygonscan.com/",
                ],
              },
            ],*/
            params: [{ 
              "chainId": "0x" + networks[network].toString("16"),
              "chainName": network == 0 ? "Ethereum network" : "Polygon",
              "rpcUrls": [network == 0 ? "https://ethereum.publicnode.com" : "https://rpc-mainnet.maticvigil.com"],
              "nativeCurrency": {
                "name": network == 0 ? "ETH" : "MATIC",
                "symbol": network == 0 ? "ETH" : "MATIC",
                "decimals": 18
              },
              "blockExplorerUrls": [network == 0 ? "https://etherscan.io" : "https://polygonscan.com/"]
            }],
          };

          library.provider
            .request(reqData)
            .then((a) => {
              location.reload();
              commit("setActive", false);
              setTimeout(() => {
                commit("setActive", true);
                this._vm.$registerContract(library);
              }, 200);
            })
            .catch((a) => {});
        });
    },
    async connect({ state, commit, dispatch }) {
      //console.log("connect");
      if (state.account != null) {
        if (
          networks[0] == library.provider.chainId ||
          networks[1] == library.provider.chainId
        ) {
          //console.log("ok");
        } else {
          dispatch("changeNetwork", 0);
          return;
        }
      }
      if (state.active || waitingForConnect) return;
      waitingForConnect = true;
      const provider = await state.web3Modal.connect();
      library = new ethers.providers.Web3Provider(provider);

      library.pollingInterval = 12000;
      commit("setLibrary", library);

      const accounts = await library.listAccounts();
      if (accounts.length > 0) {
        commit("setAccount", accounts[0]);
        let balance = await library.getBalance(accounts[0]);
        balance = (parseFloat(balance.toString()) / 10 ** 18).toFixed(4);

        commit("setBalance", balance);
      }
      const network = await library.getNetwork();

      provider.on("disconnected", (info) => {
        dispatch("resetApp");
      });

      provider.on("disconnect", (code, reason) => {
        dispatch("resetApp");
      });

      provider.on("connect", async (info) => {
        let chainId = parseFloat(info.chainId);
      });

      provider.on("accountsChanged", async (accounts) => {
        dispatch("resetApp");
      });
      provider.on("chainChanged", async (chainId) => {
        chainId = parseInt(chainId);
        //console.log("chainChanged",chainId);
        if (networks[0] == chainId || networks[1] == chainId) {
          //console.log("jo");
          commit("set", { active: true, network: networks.indexOf(chainId) });
          //waitingForConnect = false;
          //dispatch("connect");
        } else {
          //console.log("ne");
          commit("setActive", false);
        }
      });

      if (networks[0] == library.provider.chainId)
        commit("set", { network: 0 });
      else if (networks[1] == library.provider.chainId)
        commit("set", { network: 1 });
      else {
        dispatch("changeNetwork", 0);
        return;
      }
      this._vm.$registerContract(library);

      if (state.account != null) {
        commit("set", {
          myART: await this._vm.$web3Call("balanceOf", state.account),
        });
      }
      commit("setActive", true);
    },
    async resetApp({ state, commit }) {
      waitingForConnect = false;
      if (!waitingForClear) console.log("resetApp");
      try {
        state.web3Modal.disconnect();
        if (state.web3Modal.cachedProvider) {
          waitingForClear = true;
          await state.web3Modal.clearCachedProvider();
          waitingForClear = false;
        }
      } catch (error) {
        waitingForClear = false;
        console.error(error);
      }

      commit("setAccount", null);
      commit("setActive", false);
    },

    async getInfo(state, params) {
      if(state.state.network == 0) return;
      const contractState = await blockchain.call("info");
      this.commit("setContractInfo", contractState);
    },

    async getProprosals(state, params) {
      if(state.state.network == 0) return [];
      const proprosals = [];
      const contractState = await blockchain.call("info");
      this.commit("setContractInfo", contractState);
      const proposalsLength = await blockchain.call("proposalsLength");

      for (let i = 0; i < Math.max(0, proposalsLength); i++) {
        let id = i;
        const result = await blockchain.call("getProposal", id);
        proprosals.push({
          actionAddress: result.actionAddress,
          addresses: result.addresses,
          actionType: parseFloat(result.actionType),
          amount: parseFloat(result.amount),
          endTime: parseFloat(result.endTime * 1000),
          id: id,
          percents: result.percents,
          result: parseFloat(result.result),
          voters: result.voters,
          votes: result.votes.map(parseFloat),
        });
      }

      const sorted = proprosals.sort((a, b) => b.id - a.id);

      const filteredProprosals = sorted.filter((obj) => {
        return obj.actionType === params.actionType;
      });

      return [...filteredProprosals.slice(0, params.count)];
    },
  },
};
export default web3ModalStore;
