export default [
  {
    path: "/",
    name: "wallet",
    component: () => lazyLoadView(import("../views/AddWallet")),
    meta: {
      layout: "default",
      auth: true,
    },
  },

  {
    path: "/remove-wallet",
    name: "remove-wallet",
    component: () => lazyLoadView(import("../views/RemoveWallet")),
    meta: {
      layout: "default",
      auth: true,
    },
  },

  {
    path: "/set-payment",
    name: "set-payment",
    component: () => lazyLoadView(import("../views/SetPayment")),
    meta: {
      layout: "default",
      auth: true,
    },
  },

  {
    path: "/payment",
    name: "payment",
    component: () => lazyLoadView(import("../views/Payment")),
    meta: {
      layout: "default",
      auth: true,
    },
  },

  {
    path: "/payment-art",
    name: "payment-art",
    component: () => lazyLoadView(import("../views/PaymentArt")),
    meta: {
      layout: "default",
      auth: true,
    },
  },

  {
    path: "/payment-eth",
    name: "payment-eth",
    component: () => lazyLoadView(import("../views/PaymentEth")),
    meta: {
      layout: "default",
      auth: true,
    },
  },

  {
    path: "/wallets",
    name: "wallets",
    component: () => lazyLoadView(import("../views/Wallets")),
    meta: {
      layout: "default",
      auth: true,
    },
  },

  {
    path: "/transfer",
    name: "transfer",
    component: () => lazyLoadView(import("../views/Transfer.vue")),
    meta: {
      layout: "default",
      auth: true,
    },
  },

  {
    path: "/login",
    name: "login",
    component: () => lazyLoadView(import("../views/Login")),
    meta: {
      layout: "auth",
      auth: false,
    },
  },

  {
    path: "/*",
    name: "error",
    component: () => lazyLoadView(import("../views/NotFound")),
    meta: {
      layout: "error",
    },
  },
];

const lazyLoadView = (AsyncView) => {
  const AsyncHandler = () => ({
    component: AsyncView,
    delay: 400,
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children);
    },
  });
};
