const axios = require("axios").default;

export const request = (method, url, data = null) => {
  if (!process.browser) {
    return new Promise(function (resolve, reject) {
      if (method == "GET") {
        axios
          .get(url)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  } else
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      xhr.withCredentials = true;
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(JSON.parse(xhr.response));
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
      xhr.send(data);
    });
};
