<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-img
          v-bind="attrs"
          v-on="on"
          contain
          height="32"
          width="32"
          :class="{ hover: attrs['aria-expanded'] == 'true' }"
          class="btn-img ml-2 mr-3"
          :src="items[web3.network].icon"
        />
      </template>
      <v-list>
        <v-subheader>{{ $t("components.selectMarket") }}</v-subheader>
        <v-list-item-group
          :value="web3.network"
          @change="(value) => $store.dispatch('changeNetwork', value)"
          color="primary"
        >
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-img
              contain
              height="32"
              max-width="32"
              width="32"
              class="mr-3"
              :src="item.icon"
            />
            <v-list-item-content>
              <v-list-item-title class="d-flex">
                <span class="d-block mr-6">{{ item.text }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import ethereumLogo from "./../assets/ethereum.svg";
import polygonLogo from "./../assets/polygon.svg";
export default {
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "Ethereum", icon: ethereumLogo },
      { text: "Polygon", icon: polygonLogo },
    ],
  }),
  computed: {
    web3: function () {
      return this.$store.state.web3;
    },
  },
};
</script>

<style type="text/css">
.btn-img {
  cursor: pointer;
  opacity: 0.5;
}
.btn-img:hover,
.btn-img.hover {
  opacity: 1;
}
</style>
