import { shortAddress } from "@/helpers/shortAddress";
import { priceFormatter } from "@/helpers/priceFormatter";
import { request } from "@/helpers/request";

export default {
  install(Vue, options) {
    Vue.prototype.$shortAddress = shortAddress;
    Vue.prototype.$priceFormatter = priceFormatter;
    Vue.prototype.$request = request;
  },
};
